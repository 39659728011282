import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthService } from '../auth/auth.service';
import { Firestore, doc, getDoc } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root',
})
export class UserGuard implements CanActivate {
  constructor(
    private _router: Router,
    private authService: AuthService,
    private firestore: Firestore
  ) {}

  canActivate(): boolean {
    const userData = localStorage.getItem('user');

    if (!userData) {
      console.warn("⛔ [UserGuard] Usuário não encontrado. Redirecionando para login...");
      this._router.navigate(['/login']); // Corrigindo o nome para `_router`
      return false;
    }
    return true;
  }
}
