const originalSetItem = localStorage.setItem;

localStorage.setItem = function (...args: [string, string]) {
    if (args[0] === 'role') {
        console.trace('Setting role in localStorage');
    }
    originalSetItem.apply(this, args); // Passa os argumentos corretamente para o setItem original
};

import { bootstrapApplication } from '@angular/platform-browser';
import { AppComponent } from './app/app.component';
import { appConfig } from 'app/app.config';

bootstrapApplication(AppComponent, appConfig)
  .catch((err) => console.error(err));



