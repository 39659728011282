import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CommonModule } from '@angular/common';
import { LinksService } from 'app/modules/module_links/services/links.service';
import { Links } from 'app/modules/module_links/models/links.model';
import {BodyClassService} from "../../services/body-class/body-class.service";
import {Button} from "primeng/button";

@Component({
  standalone: true,
  selector: 'app-static-page-view',
  templateUrl: './static-page-view.component.html',
  styleUrls: ['./static-page-view.component.less'],
  imports: [CommonModule, Button]
})
export class StaticPageViewComponent implements OnInit {
  pageData: Links | null = null; // Dados da página
  loading: boolean = true; // Estado de carregamento
  errorMessage: string | null = null; // Mensagem de erro

  constructor(
    private route: ActivatedRoute,
    private linksService: LinksService,
    private bodyClassService: BodyClassService
  ) {
    this.bodyClassService.addClass('link-tree')
  }

  ngOnInit() {
    // Obtém o slug da rota
    const slug = this.route.snapshot.paramMap.get('slug');
    if (slug) {
      this.loadPageFromFirebase(slug);
    } else {
      this.errorMessage = 'Slug inválido.';
      this.loading = false;
    }
  }

  /**
   * Carrega uma página específica do Firebase com base no `slug` fornecido.
   * Busca a lista de links armazenados no Firebase, localiza a página correspondente e processa seus botões.
   *
   * @param slug O identificador único da página (URL amigável).
   */
  loadPageFromFirebase(slug: string) {
    this.linksService.listarLinks().subscribe((links: Links[]) => {
      const page = links.find((link) => link.url_slug === slug);

      if (page) {
        // Filtra os botões para garantir que não tenha links inválidos
        page.buttons = page.buttons?.filter(
          (button) => button.titulo !== 'vazio' && button.url !== 'vazio'
        ) || [];

        this.pageData = page;
      } else {
        this.errorMessage = 'Página não encontrada.';
      }

      this.loading = false;
    }, (error: any) => {
      console.error('Erro ao carregar os dados:', error);
      this.errorMessage = 'Erro ao carregar os dados. Tente novamente mais tarde.';
      this.loading = false;
    });
  }
  openLink(url: string): void {
    window.open(url, '_blank', 'noopener,noreferrer');
  }
}

