import {Injectable, Inject, PLATFORM_ID, Renderer2, RendererFactory2} from '@angular/core';
import {isPlatformBrowser} from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class BodyClassService {
  private renderer: Renderer2;

  private isBrowser: boolean;

  constructor(rendererFactory: RendererFactory2, @Inject(PLATFORM_ID) private platformId: object) {
    this.renderer = rendererFactory.createRenderer(null, null);
    this.isBrowser = isPlatformBrowser(this.platformId); // Verifica se está no navegador
  }

  /**
   * Adiciona uma classe específica ao elemento `<body>`.
   * @param className O nome da classe CSS a ser adicionada ao `body`.
   */
  addClass(className: string): void {
    if (this.isBrowser) {
      this.renderer.addClass(document.body, className);
    }
  }

  /**
   * Remove uma classe específica do elemento `<body>`.
   * @param className O nome da classe CSS a ser removida do `body`.
   */
  removeClass(className: string): void {
    if (this.isBrowser) {
      this.renderer.removeClass(document.body, className);
    }
  }

  /**
   * Define uma única classe no elemento `<body>`, removendo todas as outras existentes.
   * Isso garante que apenas a classe fornecida permaneça aplicada ao `body`.
   * @param className O nome da nova classe CSS que substituirá as classes anteriores do `body`.
   */
  setClass(className: string): void {
    if (this.isBrowser) {
      document.body.className = className;
    }
  }

}
