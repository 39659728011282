import {Routes} from '@angular/router';

// Módulo para redirecionar usando um slug (encurtador de links)
import {RedirectComponent} from "./module_short-link/redirect/redirect.component";

// Componentes
import {StaticPageViewComponent} from './pages/static-page-view/static-page-view.component';
import {HomeComponent} from "./pages/home/home.component";


// Services
import {UserGuard} from "./services/guards/user.guard";
import {AdminGuard} from "./services/guards/admin.guard";
import {HomepageComponent} from './pages/homepage/homepage.component';

export const routes: Routes = [
  {
    path: "",
    component: HomeComponent
  },
  {
    path: "login",
    component: HomeComponent
  },
  {
    path: "home",
    component: HomepageComponent,
    canActivate: [AdminGuard]
  },
  {
    path: "painel",
    loadChildren: () => import('./admin/painel/painel.module').then(m => m.PainelModule),
    canActivate: [UserGuard], // Protege com UserGuard para todos os usuários autenticados
  },
  {path: 'p/:slug', component: StaticPageViewComponent},
  {path: ':slug', component: RedirectComponent},



];
