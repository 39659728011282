<p-toast/>

<main class="login-page">
  <div class="login-background"></div>

  <div class="login-container">

    <!-- Seção da imagem -->
    <div class="image-section">
      <img src="/assets/img/bg_login.jpg" alt="Imagem de login">
    </div>

    <!-- Seção de informações e login -->
    <div class="content-section">
      <div class="info-section">
        <img class="info-section__img" src="/assets/img/logoOsceia.png" alt="Imagem de login">

        <div class="info-section__info">
          <h2>Educandário Espírita Eurípedes Barsanulfo</h2>
        </div>

      </div>

      <div class="form-section">
        <h4>Insira os dados nos campos abaixo</h4>
        <form [formGroup]="formControl" (ngSubmit)="onSubmit()">
          <div class="inputs-field">
            <div class="field">
              <label for="email">Email</label>
              <input
                class="w-full"
                id="email"
                type="email"
                pInputText
                formControlName="email"
                placeholder="Digite seu email"
                [ngClass]="{ 'p-invalid': isInvalid('email') }"
              />
              @if (isInvalid('email')) {
                <small class="p-error">
                  {{ getErrorMessage('email') }}
                </small>
              }
            </div>

            <div class="field">
              <label for="password">Senha</label>
              <input
                class="w-full"
                id="password"
                type="password"
                pInputText
                formControlName="password"
                placeholder="Digite sua senha"
                [ngClass]="{ 'p-invalid': isInvalid('password') }"
              />
              @if (isInvalid('password')) {
                <small class="p-error">
                  {{ getErrorMessage('password') }}
                </small>
              }

            </div>
          </div>


          <p-button class="w-full" pButton type="submit" label="Acessar" severity="primary" [disabled]="formControl.invalid"/>
        </form>

        <!--<p class="signup-link">Não tem uma conta? <a href="#">Cadastre-se</a></p>-->
      </div>
    </div>

  </div>
</main>
