<!-- Página carregada -->
@if (!loading && pageData) {
  <div class="page-container">
    <!-- Logo -->
    <img class="logo" src="/assets/img/logoOsceia.png" alt="Logo da Página" loading="lazy">
    <h1>Educandário Espírita Eurípedes Barsanulfo</h1>

    <!-- Título da página -->
    <h1 class="page-title">{{ pageData.title }}</h1>

    <!-- Ícones de redes sociais -->
    <div class="social-icons">
      <a href="https://www.facebook.com/osceia.br/" target="_blank"><i class="pi pi-facebook"></i></a>
      <a href="https://www.instagram.com/osceia.br/" target="_blank"><i class="pi pi-instagram"></i></a>
      <a href="https://www.youtube.com/channel/UC4WWlV2k-6BTBg7gKUHOzNQ/videos?view_as=subscriber" target="_blank">
        <i class="pi pi-youtube"></i>
      </a>
    </div>

    <!-- Botões -->
    <div class="buttons-container post-it">
      @for (button of pageData.buttons; track button) {
        <p-button
          [label]="button.titulo"
          icon="pi pi-thumbtack"
          class="link-button"
          (click)="openLink(button.url)">
        </p-button>
      }
    </div>
  </div>
}

<!-- Tela de carregamento -->
@if (loading) {
  <div class="loading-message">
    <p>Carregando...</p>
  </div>
}

<!-- Mensagem de erro -->
@if (errorMessage) {
  <div class="error-message">
    <p>{{ errorMessage }}</p>
  </div>
}
