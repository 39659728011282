import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { UserCredential } from "@angular/fire/auth";
import { CommonModule } from '@angular/common';

// PrimeNG
import { ButtonModule } from 'primeng/button';
import { CardModule } from "primeng/card";
import { FloatLabelModule } from "primeng/floatlabel";
import { InputTextModule } from 'primeng/inputtext';
import { PasswordModule } from "primeng/password";
import { MessageService } from "primeng/api";
import { ToastModule } from "primeng/toast";


// Services
import { FireAuthService } from "../../services/fire-auth/fire-auth.service";
import { AuthService } from "../../services/auth/auth.service";

// Models
import {Router} from "@angular/router";
import {NgOptimizedImage} from "@angular/common";
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';



@Component({
  selector: 'app-home',
  standalone: true,
  imports: [
    ButtonModule,
    CardModule,
    FloatLabelModule,
    InputTextModule,
    PasswordModule,
    ReactiveFormsModule,
    ToastModule,
    NgOptimizedImage,
    CommonModule,
  ],
  templateUrl: './home.component.html',
  styleUrl: './home.component.less',
  providers: [MessageService],
  schemas: [CUSTOM_ELEMENTS_SCHEMA] // Adiciona suporte para Web Components
})
export class HomeComponent implements OnInit {
  formControl!: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private fireAuthService: FireAuthService,
    private messageService: MessageService,
    private authService: AuthService,
    private router: Router
  ) {
    this.formControl = this.formBuilder.group({
      email: new FormControl('', [Validators.required, Validators.email]),
      password: new FormControl('', [Validators.required, Validators.minLength(6)]),
    });
  }

  isLoading = true;

  ngOnInit(): void {
    this.initializeForm();
  }


  private initializeForm(): void {
    this.formControl = this.formBuilder.group({
      email: new FormControl('', [Validators.required, Validators.email]),
      password: new FormControl('', [Validators.required]),
    });
  }

  /**
   * Lida com a submissão do formulário de login.
   */
  onSubmit(): void {
    if (this.formControl.valid) {
      const { email, password } = this.formControl.value;

      this.fireAuthService.signInWithEmailAndPassword(email, password)
        .then(async (user: UserCredential) => {
          const uid = user.user?.uid || null;

          if (uid) {
            const userDataFromDb = await this.fireAuthService.fetchUserData(uid);

            const userData = {
              uid,
              email: user.user?.email || null,
              displayName: userDataFromDb?.name || 'Usuário',
              role: userDataFromDb?.role || 'user'
            };

            localStorage.setItem('user', JSON.stringify(userData));

            this.messageService.add({
              severity: 'success',
              summary: 'Bem-vindo',
              detail: 'Login realizado com sucesso!'
            });

            this.router.navigate(['/painel']);
          } else {
            console.error('UID do usuário não encontrado.');
          }
        })
        .catch((error) => {
          console.error('Erro ao fazer login:', error);

          let errorMessage = 'Erro ao fazer login. Tente novamente mais tarde.';
          if (error.code === 'auth/user-not-found') {
            errorMessage = 'Usuário não encontrado. Verifique suas credenciais.';
          } else if (error.code === 'auth/wrong-password') {
            errorMessage = 'Senha incorreta. Por favor, tente novamente.';
          } else if (error.code === 'auth/invalid-credential') { // Corrigido aqui
            errorMessage = 'Dados incorretos. Por favor, tente novamente.';
          }

          this.messageService.add({
            severity: 'error',
            summary: 'Erro',
            detail: errorMessage
          });
        });
    } else {
      this.messageService.add({
        severity: 'warn',
        summary: 'Ops!',
        detail: 'Você precisa digitar seus dados corretamente.'
      });
    }
  }

  /**
   * Retorna a mensagem de erro correspondente ao campo fornecido.
   *
   * @param field O nome do campo a ser validado.
   * @returns Uma string contendo a mensagem de erro apropriada.
   */

  getErrorMessage(field: string): string {
    const control = this.formControl.get(field);

    if (control?.hasError('required')) {
      return field === 'email' ? 'O email é obrigatório' : 'A senha é obrigatória';
    }
    if (control?.hasError('email')) {
      return 'Formato de email inválido';
    }
    if (control?.hasError('minlength')) {
      return 'A senha deve ter pelo menos 6 caracteres';
    }

    return '';
  }

  /**
   * Verifica se um campo do formulário está inválido.
   *
   * @param field O nome do campo a ser verificado.
   * @returns Um booleano indicando se o campo é inválido.
   */
  isInvalid(field: string): boolean {
    const control = this.formControl.get(field);
    return control?.invalid && (control.dirty || control.touched) || false;
  }

}
