import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { FireAuthService } from '../fire-auth/fire-auth.service';
import { AuthService } from '../auth/auth.service';
import { Firestore, doc, getDoc } from '@angular/fire/firestore';
import { Observable, of } from 'rxjs';
import { switchMap, map, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AdminGuard implements CanActivate {
  constructor(private authService: AuthService, private firestore: Firestore, private router: Router) {}

  canActivate(): Observable<boolean> {
    return this.authService.user$.pipe(
      switchMap((user) => {
        if (!user) {
          this.router.navigate(['/login']);
          return of(false);
        }

        const userDocRef = doc(this.firestore, `users/${user.uid}`);
        return getDoc(userDocRef).then((snapshot) => {
          if (snapshot.exists()) {
            const userData = snapshot.data() as { role: string };
            if (userData.role === 'admin') {
              return true;
            }
          }
          this.router.navigate(['/']);
          return false;
        });
      }),
      catchError(() => {
        this.router.navigate(['/']);
        return of(false);
      })
    );
  }
}
